<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <!-- @hide="hideDropdownFormFilter"
          @show="showDropdownFormFilter"
          @toggle="toggleDropdownFormFilter" -->
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã bệnh nhân"
                    placeholder="Nhập mã bệnh nhân"
                    name="code"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-input
                    label="Tên bệnh nhân"
                    placeholder="Nhập tên bệnh nhân"
                    name="name"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-select
                    label="Giới tính"
                    placeholder="--- Chọn giới tính ---"
                    name="gender"
                    :options="isGenderOpts"
                    :value.sync="filter.gender"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <label for="age">Độ tuổi</label>
                      <b-row>
                        <b-col>
                          <basic-input
                            placeholder="Từ"
                            name="startAge"
                            inputType="number"
                            v-validate="'numeric'"
                            data-vv-as="Tuổi từ"
                            :state="validateState('startAge')"
                            :invalidFeedback="errors.first('startAge')"
                            :value.sync="filter.ageStart"
                          ></basic-input>
                        </b-col>
                        <b-col>
                          <basic-input
                            placeholder="Đến"
                            name="endAge"
                            v-validate="'numeric'"
                            data-vv-as="Tuổi đến"
                            :state="validateState('endAge')"
                            :invalidFeedback="errors.first('endAge')"
                            inputType="number"
                            :value.sync="filter.ageEnd"
                          ></basic-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <basic-select
                    label="Tình trạng bệnh tiểu đường"
                    placeholder="--- Chọn tình trạng bệnh ---"
                    name="diabetesStatus"
                    :options="diabetesStateOptions"
                    :value.sync="filter.diabetesStatus"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Quốc gia"
                    placeholder="--- Chọn quốc gia ---"
                    name="nation"
                    :apiPath="'/Division/Nations'"
                    :searchField="'searchTerm'"
                    :value.sync="filter.nation"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Tỉnh thành"
                    placeholder="--- Chọn tỉnh thành ---"
                    name="province"
                    :apiPath="'/Division/Provinces'"
                    :searchField="'searchTerm'"
                    :searchParams="{ nationId: filter.nation }"
                    :value.sync="filter.provinceId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Quận / huyện"
                    placeholder="--- Chọn quận / huyện ---"
                    name="district"
                    :apiPath="'/Division/Dictricts'"
                    :searchField="'searchTerm'"
                    :searchParams="{ provinceId: filter.provinceId }"
                    :value.sync="filter.district"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Phường xã"
                    placeholder="--- Chọn phường xã ---"
                    name="wards"
                    :apiPath="'/Division/Wards'"
                    :searchField="'searchTerm'"
                    :searchParams="{ districtId: filter.district }"
                    :value.sync="filter.wards"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Gói dịch vụ"
                    placeholder="--- Chọn gói dịch vụ ---"
                    name="servicePack"
                    :options="[]"
                    disabled
                    :value.sync="filter.servicePack"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-date-picker
                    label="Thời gian tạo từ"
                    placeholder="Nhập thời gian tạo từ lúc"
                    name="createDatetime"
                    :value.sync="filter.createDatetime"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Bệnh viện"
                    placeholder="--- Chọn ---"
                    name="hospital"
                    disabled
                    :options="[]"
                    :value.sync="filter.hospital"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <basic-input
                    label="Số điện thoại"
                    placeholder="Số điện thoại"
                    name="phoneNumber"
                    input-number
                    v-validate="'numeric|max:10'"
                    data-vv-as="Số điện thoại"
                    :state="validateState('phoneNumber')"
                    :invalidFeedback="errors.first('phoneNumber')"
                    :value.sync="filter.phoneNumber"
                    maxlength="10"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="filter.inactive"
                    :options="[
                      { text: 'Lọc người dùng Inactive', value: false },
                    ]"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="user-app-list-page__body mb-6 mt-6">
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :selectAction="false"
                  :searchAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td>
                      <div class="d-flex align-items-center mt-5">
                        <avatar
                          v-if="item"
                          size="40px"
                          :text="item.fullName"
                          :src="item.imageUrl && item.imageUrl.url"
                          :rounded="true"
                        ></avatar>
                        <div class="d-flex flex-column ml-5">
                          <p
                            class="mb-0"
                            style="
                              font-weight: 600;
                              font-size: 13px;
                              color: #515356;
                            "
                          >
                            {{ item.fullName }}
                          </p>
                          <p
                            class="mt-2 mb-0"
                            style="font-size: 12px; color: #888c9f"
                          >
                            Mã số: {{ item.code || 0
                            }}{{
                              item.ownPackage
                                ? `, ${item.ownPackage.name}`
                                : ', Thành viên Cơ Bản'
                            }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_copy="false"
                        :show_delete="false"
                        :show_edit="isWritePermission"
                        :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                        @view="viewItem"
                        @edit="editItem"
                      >
                        <template v-if="isWritePermission">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                          >
                            <a
                              class="navi-link cursor-pointer"
                              @click.stop="showPopupConfirm(item)"
                            >
                              <span
                                class="menu-icon svg-icon svg-icon-sm"
                                :class="
                                  item.active ? 'text-danger' : 'text-primary'
                                "
                              >
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/power.svg"
                                />
                              </span>
                              <span
                                class="success navi-text"
                                :class="
                                  item.active ? 'text-danger' : 'text-primary'
                                "
                              >
                                {{ item.active ? 'Inactive' : 'Active' }}
                              </span>
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                    </td>
                    <td>
                      {{ item.age }}
                    </td>
                    <td>
                      {{ item.gender }}
                    </td>
                    <td>
                      {{
                        $moment(item.createDatetime * 1000).format('DD/MM/YYYY')
                      }}
                    </td>
                    <td>
                      {{ item.nation && item.nation.name }}
                    </td>
                    <td>
                      {{ item.province && item.province.name }}
                    </td>
                    <td>
                      {{ item.district && item.district.name }}
                    </td>
                    <td>
                      {{ item.ward && item.ward.name }}
                    </td>
                    <td>
                      <div style="max-width: 100px">
                        <user-state :isActive="item.active" />
                      </div>
                    </td>
                  </template>
                </template-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      loading: false,
      diabetesStateOptions: [
        { id: 1, name: 'Tiền đái tháo đường' },
        { id: 2, name: 'Đái tháo đường típ 1' },
        { id: 3, name: 'Đái tháo đường típ 2' },
      ],
      isToggleDropdownFormFilter: false,
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        code: null,
        name: null,
        gender: null,
        ageStart: null,
        ageEnd: null,
        hospital: null,
        diabetesStatus: null,
        createDatetime: null,
        nationId: null,
        provinceId: null,
        districtId: null,
        wardId: null,
        inactive: null,
      },
      sort: {
        by: null,
        order: null,
      },
      isActiveOpts: [
        { id: true, name: 'Active' },
        { id: false, name: 'Inactive' },
      ],
      isGenderOpts: [
        { id: 1, name: 'Nam' },
        { id: 2, name: 'Nữ' },
      ],
      column: [
        {
          key: 'name',
          label: 'Bệnh nhân',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Độ tuổi',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'country',
          label: 'Quốc gia',
          sortable: false,
        },
        {
          key: 'province',
          label: 'Tỉnh Thành',
          sortable: false,
        },
        {
          key: 'district',
          label: 'Quận',
          sortable: false,
        },
        {
          key: 'wards',
          label: 'Huyện',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Trạng Thái',
          sortable: false,
        },
      ],
      data: [],
    };
  },
  computed: {
    searchParams() {
      return {
        name: this.filter.name,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page': {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    'paging.size': {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        this.veeFields[ref].dirty &&
        this.veeFields[ref].validated
      ) {
        return this.veeFields[ref].valid;
      }
      return null;
    },
    hideDropdownFormFilter(bvEvent) {
      if (this.isToggleDropdownFormFilter) {
        bvEvent.preventDefault();
      }
    },
    showDropdownFormFilter(bvEvent) {
      if (!this.isToggleDropdownFormFilter) {
        bvEvent.preventDefault();
      }
    },
    toggleDropdownFormFilter() {
      this.isToggleDropdownFormFilter = !this.isToggleDropdownFormFilter;
    },
    pagingAction() {
      this.loadData();
    },
    searchRequest() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        } else {
          this.loadData();
          document.body.click();
        }
      });
    },
    resetRequest() {
      this.filter.code = null;
      this.filter.name = null;
      this.filter.gender = null;
      this.filter.ageStart = null;
      this.filter.ageEnd = null;
      this.filter.createDatetime = null;
      this.filter.nationId = null;
      this.filter.provinceId = null;
      this.filter.districtId = null;
      this.filter.wardId = null;
      this.filter.inactive = [];
      this.filter.diabetesStatus = null;
      this.filter.wards = null;
      this.filter.district = null;
      this.filter.nation = null;
      this.filter.phoneNumber = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    viewItem(item) {
      this.$router.push({
        name: 'user_app_view',
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'user_app_update',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    showPopupConfirm(item) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn ${
          item.active ? 'inactive' : 'active'
        } app user ${item.fullName} (mã số ${item.code}) không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary rounded',
            closeModal: true,
          },
          confirm: {
            text: `${item.active ? 'Inactive' : 'Active'}`,
            value: true,
            visible: true,
            className: `btn btn-${item.active ? 'inactive' : 'active'} rounded`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteItem(item);
          }
        }.bind(this),
      );
    },
    async deleteItem(item) {
      this.loading = true;
      let formData = new FormData();
      formData.append('patientId', item.id);
      formData.append('active', !item.active);
      try {
        await this.$api.put(`Patient/Input`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhật công',
        });
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: 'Không thể cập nhật trạng thái',
        });
      } finally {
        this.loading = false;
      }
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return null;
      if (phoneNumber.startsWith('0')) return phoneNumber.replace('0', '+84');
      return phoneNumber;
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      let inactive = this.filter.inactive?.[0];
      let createDate = this.filter.createDatetime
        ? this.$moment(this.filter.createDatetime).format('X')
        : null;
      let query = {
        ...this.searchParams,
        code: this.filter.code,
        name: this.filter.name,
        gender: this.filter.gender,
        ageStart: this.filter.ageStart,
        ageEnd: this.filter.ageEnd,
        nationId: this.filter.nationId,
        provinceId: this.filter.provinceId,
        districtId: this.filter.district,
        wardId: this.filter.wards,
        diabetesStatus: this.filter.diabetesStatus,
        phoneNumber: this.formatPhoneNumber(this.filter.phoneNumber),
        inactive: inactive,
        createDatetime: createDate,
      };
      this.$api
        .get('Patient', {
          params: query,
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
